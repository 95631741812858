import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TruthrPrivacy from "../views/TruthrPrivacy.vue";
import TruthrSupport from "../views/TruthrSupport.vue";
import TruthrEULA from "../views/TruthrEULA.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/adav1privacy",
    name: "adav1privacy",
    component: TruthrPrivacy,
  },
  {
    path: "/adav1support",
    name: "adav1support",
    component: TruthrSupport,
  },
  {
    path: "/adav1eula",
    name: "adav1eula",
    component: TruthrEULA,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
