<template>
  <div class="home">
    <h1>SS3 HOLDINGS</h1>
    <h2>iOS dev studio bringing you the ADAv1 iOS app.</h2>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
}
</style>
